import "swiper/css";
import "swiper/css/effect-fade";
import { Trans, useTranslation } from "react-i18next";
import useCurrLang from "hooks/useCurrLang";
import { useState } from "react";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { Link } from "react-router-dom";
import frogImg from "images/en/shareholders/frog.png";
import calendarIcon from "icons/calendar.svg";
import instagramIcon from "icons/instagram.svg";
import linkedinIcon from "icons/linkedin.svg";
import downloadIcon from "icons/download.svg";
import useShareholders, { Item, Report } from "hooks/useShareholders";
import backArrow from "icons/back-arrow.svg";
import forwardArrow from "icons/forward-arrow.svg";

const ForShareholders = () => {
  const { t } = useTranslation();
  const currLang = useCurrLang();

  const [founderSwiper, setFounderSwiper] = useState<SwiperClass | null>();
  const [directorsSwiper, setDirectorSwiper] = useState<SwiperClass | null>();

  const { founders, directors, reports } = useShareholders();

  const renderFounders = () => founders.map(renderSlideItem);
  const renderDirectors = () => directors.map(renderSlideItem);

  const renderSlideItem = (item: Item) => {
    return (
      <SwiperSlide key={item.name}>
        <img src={item.image} alt={item.name} className="img-fluid avatar" />
        <div className="body">
          <h4>{item.name}</h4>
          <div className="subtitle">
            <p>{item.type}</p>
            {item.isSocials ? (
              <div className="socials">
                <Link to={item.linkLinkedin} target="_blank">
                  <img src={linkedinIcon} alt="linkedin" />
                </Link>
              </div>
            ) : null}
          </div>
          <p className="description">{item.description}</p>
        </div>
      </SwiperSlide>
    );
  };

  const renderReports = () => reports.map(renderReport);
  const renderReport = (report: Report) => {
    return (
      <div className="report-item" key={report.name}>
        <h4>{report.name}</h4>
        <img src={downloadIcon} alt="download" />
      </div>
    );
  };

  return (
    <section id="shareholders">
      <main>
        <div className="container">
          <div className="title-container">
            <div className="title-wrapper">
              <h1>
                <Trans i18nKey="partners.Title">
                  Insights and Updates for <span>Investors</span>
                </Trans>
              </h1>
            </div>
            <div className="frog-container">
              <div className="frog-image-wrapper">
                <img
                  src={frogImg}
                  alt="frog with glasses holding orovera sign"
                />
              </div>
            </div>
          </div>
          <div className="about-container">
            <div className="title-wrapper">
              <h2>
                <Trans i18nKey="partners.aboutTitle">
                  A little about our company
                </Trans>
              </h2>
            </div>
            <div className="description-wrapper">
              <p>
                <Trans i18nKey="partners.aboutDescription">
                  As the CEO of a successful financial company, I am proud of the
                  journey we have undertaken. Our company has consistently
                  demonstrated exceptional financial performance, marked by steady
                  profitability and sustainable growth year after year.
                  Transparency and integrity are the pillars of our operations. We
                  adhere to stringent regulatory standards and maintain an
                  unwavering commitment to ethical conduct. Our clients trust us
                  because we prioritize their best interests above all else.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </main>
      <div className="news">
        <div className="container">
          <h2>
            <Trans i18nKey="partners.news.Title">
              News
            </Trans>
          </h2>
          <div className="news-wrapper">
            <div className="news-item">
              <div className="date">
                <img src={calendarIcon} alt="calendar" />
                July 1, 2023
              </div>
              <h3>
                <Link to="/news/1">
                  <Trans i18nKey="partners.news.1.Title">
                    Projekt UE
                  </Trans>
                </Link>
              </h3>
              <p>
                <Trans i18nKey="partners.news.1.Body">
                  Development of a platform for asynchronous payments between users of Google Pay, Apple Pay using social media and VISA and Mastercard.
                </Trans>
              </p>
            </div>
            <div className="news-item">
              <div className="date">
                <img src={calendarIcon} alt="calendar" />
                February 14, 2024
              </div>
              <h3>
                <Link to="/news/2">
                  <Trans i18nKey="partners.news.2.Title">
                    Public beta testing
                  </Trans>
                </Link>
              </h3>
              <p>
                <Trans i18nKey="partners.news.2.Body">
                  We are excited to announce the launch of the public beta testing of our newest service for convenient, secure, and fast payments on social networks. Your opinion is extremely important to us, and we are committed to improving our service based on your feedback.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="founders">
        <div className="container">
          <header>
            <div className="text">
              <h2 className="mb-md">
                <Trans i18nKey="partners.founders.Title">
                  Company
                  <br />
                  Founders
                </Trans>
              </h2>
              <p>
                <Trans i18nKey="partners.founders.Description">
                  Our mission is to empower individuals and businesses to take
                  control of their financial well-being through our finance app.
                </Trans>
              </p>
            </div>
            <div className="navigation">
              <div className="nav-wrapper">
                <div
                  className="arrow-wrapper back-arrow"
                  onClick={() => founderSwiper?.slidePrev()}
                >
                  <img src={backArrow} alt="back arrow" />
                </div>
                <div
                  className="arrow-wrapper forward-arrow"
                  onClick={() => founderSwiper?.slideNext()}
                >
                  <img src={forwardArrow} alt="forward arrow" />
                </div>
              </div>
            </div>
          </header>
          <Swiper
            slidesPerView={4}
            spaceBetween={20}
            onSwiper={setFounderSwiper}
            className="d-none d-lg-flex"
          >
            {renderFounders()}
          </Swiper>
          <div className="d-lg-none swiper-wrapper">{renderFounders()}</div>
        </div>
      </div>
      <div className="directors">
        <div className="container">
          <header>
            <div className="text">
              <h2 className="mb-md">
                <Trans i18nKey="partners.directors.Title">
                  Board of
                  <br />
                  directors
                </Trans>
              </h2>
              <p>
                Our mission is to empower individuals and businesses to take
                control of their financial well-being through our finance app.
              </p>
            </div>
            <div className="navigation">
              <div className="nav-wrapper">
                <div
                  className="arrow-wrapper back-arrow"
                  onClick={() => directorsSwiper?.slidePrev()}
                >
                  <img src={backArrow} alt="back arrow" />
                </div>
                <div
                  className="arrow-wrapper forward-arrow"
                  onClick={() => directorsSwiper?.slideNext()}
                >
                  <img src={forwardArrow} alt="forward arrow" />
                </div>
              </div>
            </div>
          </header>
          <Swiper
            slidesPerView={4}
            spaceBetween={20}
            onSwiper={setDirectorSwiper}
            className="d-none d-lg-flex"
          >
            {renderDirectors()}
          </Swiper>
          <div className="d-lg-none swiper-wrapper">{renderDirectors()}</div>
        </div>
      </div>
      {/* <div className="finance">
        <div className="container">
          <header>
            <div className="text">
              <h2 className="mb-md">
                Financial
                <br />
                Statement
              </h2>
              <p>
                Our mission is to empower individuals and businesses to take
                control of their financial well-being through our finance app.
              </p>
            </div>
            <div className="navigation"></div>
          </header>
          <div className="slider-reports-wrapper">{renderReports()}</div>
        </div>
      </div> */}
    </section>
  );
};

export default ForShareholders;
