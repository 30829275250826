import { useTranslation } from "react-i18next";

import firstFounder from "images/en/shareholders/founders/1.png";
import secondFounder from "images/en/shareholders/founders/2.png";
import thirdFounder from "images/en/shareholders/founders/3.png";
import fourthFounder from "images/en/shareholders/founders/4.png";
import fifthFounder from "images/en/shareholders/founders/5.png";
import sixthFounder from "images/en/shareholders/founders/6.png";
import seventhFounder from "images/en/shareholders/founders/7.png";

import firstDirector from "images/en/shareholders/directors/1.png";
import secondDirector from "images/en/shareholders/directors/2.png";
import thirdDirector from "images/en/shareholders/directors/3.png";
import fourthDirector from "images/en/shareholders/directors/4.png";

export type Item = {
  image: string;
  name: string;
  type: "Founder" | "Board Director";
  isSocials: boolean;
  linkLinkedin: string;
  description: string;
};

export type Report = {
  name: string;
};

const useShareholders = () => {
  const { t } = useTranslation();

  const founders: Item[] = [
    {
      image: firstFounder,
      name: t("partners.founders.1.name", "Olga Gavryshchuk"),
      type: t("partners.founders.positionFounder", "Founder"),
      isSocials: false,
      linkLinkedin: "",
      description: t("partners.founders.1.description", "Olga is a highly experienced professional with a proven track record in the startup landscape, demonstrating a keen aptitude for the creation of innovative ideas and approaches."),
    },
    {
      image: secondFounder,
      name: t("partners.founders.2.name", "Krzysztof Drzyzga"),
      type: t("partners.founders.positionFounder", "Founder"),
      isSocials: true,
      linkLinkedin: "https://www.linkedin.com/in/krzysztofdrzyzga/",
      description: t("partners.founders.2.description", "Krzysztof is a dynamic entrepreneur and astute business developer with extensive experience in the banking and payment industry."),
    },
    {
      image: thirdFounder,
      name: t("partners.founders.3.name", "Adrian Durkalec"),
      type: t("partners.founders.positionFounder", "Founder"),
      isSocials: true,
      linkLinkedin: "https://www.linkedin.com/in/adriandurkalec/",
      description: t("partners.founders.3.description", "Adrian key strengths include skilled data analysis, effective communication in international settings, and a proven record of managing multiple Fintech projects."),
    },
    {
      image: fourthFounder,
      name: t("partners.founders.4.name", "Pavlo Karatai"),
      type: t("partners.founders.positionFounder", "Founder"),
      isSocials: true,
      linkLinkedin: "https://www.linkedin.com/in/pavelkaratay/",
      description: t("partners.founders.4.description", "Pavlo is an accomplished entrepreneur with a notable history of success in IT. He is celebrated for his expertise in developing innovative technology solutions."),
    },
    {
      image: fifthFounder,
      name: t("partners.founders.5.name", "Paweł Wielądek"),
      type: t("partners.founders.positionFounder", "Founder"),
      isSocials: true,
      linkLinkedin: "https://www.linkedin.com/in/wieladekpawel/",
      description: t("partners.founders.5.description", "With a proven track record of driving success in various ventures, Paweł is a visionary leader who combines strategic acumen with an infectious drive for progress."),
    },
    {
      image: sixthFounder,
      name: t("partners.founders.6.name", "Maksym Karatai"),
      type: t("partners.founders.positionFounder", "Founder"),
      isSocials: true,
      linkLinkedin: "https://www.linkedin.com/in/max-karatai/",
      description: t("partners.founders.6.description", "A prominent team builder and accomplished professional, serves as a valued member of our board. Bringing a invaluable perspective to our company."),
    },
    {
      image: seventhFounder,
      name: t("partners.founders.7.name", "Alfa Spinaker"),
      type: t("partners.founders.positionFounder", "Founder"),
      isSocials: false,
      linkLinkedin: "",
      description: t("partners.founders.7.description", "The fund not only provides financial support but also offers valuable assistance in the growth and development of Orovera, and expertise in early-stage transactions."),
    },
  ];

  const directors: Item[] = [
    {
      image: firstDirector,
      name: t("partners.directors.1.name", "Marcin Kamil Chruściel"),
      type: t("partners.directors.positionBoardDirector", "Board Director"),
      isSocials: true,
      linkLinkedin: "https://www.linkedin.com/in/marcin-chrusciel-436b521/",
      description: t("partners.directors.1.description", "Over 20 years of experience in strategy, innovations, product & digital development, sales management, marketing & communication, start-up building, M&A processes."),
    },
    {
      image: secondDirector,
      name: t("partners.directors.2.name", "Mykola Gavryshchuk"),
      type: t("partners.directors.positionBoardDirector", "Board Director"),
      isSocials: true,
      linkLinkedin: "https://www.linkedin.com/in/mykola-gavryshchuk/",
      description: t("partners.directors.2.description", "Dynamic technology entrepreneur with a robust track record in winning business within competitive markets. Boasting over 15 years of expertise in new business development."),
    },
    {
      image: thirdDirector,
      name: t("partners.directors.3.name", "Nikita Polakovs"),
      type: t("partners.directors.positionBoardDirector", "Board Director"),
      isSocials: false,
      linkLinkedin: "",
      description: t("partners.directors.3.description", "Nikita, a distinguished finance expert, serves as a vital member of our board, leveraging over 10 years of expertise in the financial industry."),
    },
    {
      image: fourthDirector,
      name: t("partners.directors.4.name", "Maksym Karatai"),
      type: t("partners.directors.positionBoardDirector", "Board Director"),
      isSocials: true,
      linkLinkedin: "https://www.linkedin.com/in/max-karatai/",
      description: t("partners.directors.4.description", "A prominent team builder and accomplished professional, serves as a valued member of our board. Bringing a invaluable perspective to our company."),
    },
  ];

  const reports: Report[] = [
    { name: "Financial Report 2023" },
    { name: "Product Presentation 2023" },
    { name: "Financial Forecast Q1-2/2024" },
    { name: "Expansion Strategy 2023-2024" },
    { name: "Marketing Plan 2023-2024" },
    { name: "Financial Forecast Q3-4/2024" },
  ];

  return {
    founders,
    directors,
    reports,
  };
};

export default useShareholders;
